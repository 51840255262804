<template>
  <v-row>
    <v-dialog
      v-model="addDialog"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="onClose()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{editedCustomerId !== null? 'Edit Customer': 'Add New Customer'}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="onSave()"
            >
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container fluid class="mt-3">
            <v-form>
              <v-row>
                <v-col
                  cols="12"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6">
                      <v-text-field
                        v-model="customer.firstName"
                        label="First Name*"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6">
                      <v-text-field
                        v-model="customer.lastName"
                        label="Last Name*"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6">
                      <v-text-field
                        v-model="customer.address"
                        label="Address*"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6">
                      <v-text-field
                        v-model="customer.company"
                        label="Company*"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6">
                      <v-text-field
                        v-model="customer.email"
                        label="Email*"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6">
                      <v-text-field
                        v-model="customer.contact"
                        label="Contact #*"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
            <small>*indicates required field</small>
          </v-container>
        </v-card-text>
      </v-card>
      <LoadingDialog :isLoading="isLoading" />
    </v-dialog>
  </v-row>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import LoadingDialog from './shared/LoadingDialog.vue';

  export default {
    name: 'AddModal',
    props: {
      openDialog: { type: Function },
      closeDialog: { type: Function },
      editedCustomerId: { type: String },
      addDialog: { type: Boolean },
    },
    data () {
      return {
        isLoading: false,
      }
    },
    methods: {
      ...mapActions(['addCustomer', 'fetchCustomer', 'updateCustomer']),
      async onSave() {
        this.isLoading = true;
        if(this.editedCustomerId !== null){
          this.updateCustomer({...this.customer})
        } else {
          this.addCustomer({...this.customer})
        }

        this.isLoading = false;
        this.closeDialog();
      },
      onClose() {
        this.closeDialog();
      },
    },
    computed: {
      ...mapGetters(['customer']),

    },
    components: {
      LoadingDialog,
    }
  }
</script>