<template>
  <v-dialog
    v-model="viewDialog"
    persistent
    max-width="800px"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="closeViewDialog()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{box.name}}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-img
                contain
                :src="imgPath(box.productImageUrl)"
                aspect-ratio="1.7"
                v-if="!!box.productImageUrl"
              ></v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>Product Names:</td>
                      <td>{{ box.name }}</td>
                    </tr>
                    <tr>
                      <td>Description:</td>
                      <td>{{ box.description }}</td>
                    </tr>
                    <tr>
                      <td>Supplier Name:</td>
                      <td>{{ box.supplierName }}</td>
                    </tr>
                    <tr>
                      <td>Quantity:</td>
                      <td>{{ box.qty }}</td>
                    </tr>
                    <tr>
                      <td>Cost of product/service:</td>
                      <td>{{ box.cop }}</td>
                    </tr>
                    <tr>
                      <td>SRP:</td>
                      <td>{{ box.srp }}</td>
                    </tr>
                    <tr>
                      <td>Profit:</td>
                      <td>{{ box.profit }}</td>
                    </tr>
                    <tr>
                      <td>Mark up (%):</td>
                      <td>{{ box.markup }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'ViewItemModal',
    props: {
      openViewDialog: { type: Function },
      closeViewDialog: { type: Function },
      editedItemId: { type: String },
      viewDialog: { type: Boolean },
    },
    data () {
      return {

      }
    },
    methods: {
      ...mapActions(['fetchBox', 'uploadImage']),
      imgPath(name) {
        if(name)
          return `${process.env.VUE_APP_API_BASE_URL}/uploads${name}`;
      },
    },
    computed: mapGetters(['productImageName', 'box']),
  }
</script>