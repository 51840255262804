import axios from 'axios';

const state = {
  customer: {},
  customers: [],
  selectedCustomer: {},
};

const getters = {
  customer: (state) => state.customer,
  allCustomers: (state) => state.customers,
  selectedCustomer: (state) => state.selectedCustomer,
};

const actions = {
  async fetchCustomer({ commit }, id) {
    const response = id? await axios.get(`/customers/${id}`) : {data: {}};
    commit('setCustomer', response.data);
  },
  async fetchCustomers({ commit }) {
    const response = await axios.get('/customers');
    response.data.map((customer) => {
      customer.completeName = `${customer.firstName} ${customer.lastName}`
    })
    commit('setCustomers', response.data);
  },
  async addCustomer({commit}, data) {
    const response = await axios.post('/customers', data);
    commit('newCustomer', response.data);
  },
  async updateCustomer({ commit }, data) {
    const response = await axios.put(`/customers/${data._id}`, data);
    commit('updateCustomer', response.data);
  },
  async deleteCustomer({ commit }, id) {
    const response = await axios.delete(`/customers/${id}`);
    commit('deleteCustomer', response.data);
  },
  setSelectedCustomer({ commit }, data) {
    commit('setSelectedCustomer', data);
  }
};

const mutations = {
  setCustomer: (state, customer) => (state.customer = customer),
  setCustomers: (state, customers) => (state.customers = customers),
  newCustomer: (state, customer) => state.customers.push(customer),
  updateCustomer: (state, customer) => {
    const index = state.customers.findIndex(item => {
      return (customer._id === item._id)
    })
    state.customers.splice(index, 1, customer);
  },
  deleteCustomer: (state, customer) => {
    const index = state.customers.findIndex(item => {
      return (customer._id === item._id)
    })
    state.customers.splice(index, 1);
  },
  setSelectedCustomer: (state, selected) => (state.selectedCustomer = selected),
};

export default {
  state,
  getters,
  actions,
  mutations
}