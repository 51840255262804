import axios from 'axios';

const state = {
    access_token: null,
    user: null
};

const getters = {
    authenticated: (state) => !!state.access_token && !!state.user,
    current_user: (state) => state.user,
    token: (state) => state.access_token,
};

const actions = {
    async signIn({ dispatch }, data) {
        const response = await axios.post('/login', data);
        
        return dispatch('attempt', response.data.access_token);
    },

    async signUp(context, data) {
        const response = await axios.post('/users', data);
        
        return response.data;
    },

    async changePass(context, data) {
        const response = await axios.put(`/users/update-password/${data.id}`, data);
        
        return response.data;
    },

    setUser({commit}, data) {
        commit('SET_USER', data);
    },

    async attempt({ commit, state }, token) {
        if(token) {
            commit('SET_TOKEN', token)
        }
        if(!state.access_token) {
            return;
        }

        try {
            const response = await axios.get('/users/auth/me')
            
            commit('SET_USER', response.data)
        } catch (error) {
            commit('SET_TOKEN',  null)
            commit('SET_USER', null)
        }
    },

    signOut({ commit }) {
        return axios.post('/signout').then(() => {
            commit('SET_TOKEN',  null)
            commit('SET_USER', null)
        })
    }
};

const mutations = {
    SET_TOKEN: (state, token) => (state.access_token = token),
    SET_USER: (state, data) => (state.user = data),
};

export default {
    state,
    getters,
    actions,
    mutations
}