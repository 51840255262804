<template>
  <div>
    <v-container fluid>
      <h1 class="mb-5">User Profile</h1>
      <v-card
          class="mx-auto"
          elevation="2"
        >
        <v-list-item three-line v-if="user !== null">
          <v-list-item-content>
            <v-list-item-title class="text-h5">
              {{user.name}}
            </v-list-item-title>
            <v-list-item-subtitle>{{user.email}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-card-text>
          <v-btn
            v-if="!isOpen"
            color="mb-2"
            @click="togglePasswordForm()"
          >
            Change Password
          </v-btn>
          <v-row v-if="isOpen">
            <v-col sm="12" md="6">
              <v-btn
                color="mb-5"
                @click="togglePasswordForm()"
              >
                Cancel
              </v-btn>
              <v-form
                ref="form"
                v-model="valid"
                lazy-validation
              >
                <v-text-field
                  v-model="oldPassword"
                  label="Old Password"
                  type="password"
                  :rules="rules.password"
                  outlined
                  dense
                  required
                ></v-text-field>
                <v-text-field
                  v-model="newPassword"
                  label="Password"
                  type="password"
                  :rules="rules.password"
                  outlined
                  dense
                  required
                ></v-text-field>
                <v-text-field
                  v-model="rePassword"
                  label="Re-enter Password"
                  type="password"
                  :rules="[passwordConfirmationRule]"
                  outlined
                  dense
                  required
                ></v-text-field>
                <v-btn
                  :loading="loading"
                  color="primary mb-2"
                  @click="save()"
                >
                  Save
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
          
        </v-card-text>
        
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'Profile',
    data () {
      return {
        valid: false,
        loading: false,
        isOpen: false,
        oldPassword: "",
        newPassword: "",
        rePassword: "",
        rules: {
          required: [
            v => !!v || 'This field is required',
          ],
          password: [
            v => !!v || 'Password is required',
          ],
        }
      }
    },
    methods: {
      ...mapActions(['setUser', 'changePass']),
      async save() {
        this.loading = true;
        await this.$refs.form.validate()
        if(this.valid) {
          await this.changePass({id: this.user.id, oldPassword: this.oldPassword, password: this.newPassword}).then(() => {
            this.loading = false;
            this.isOpen = false;
            this.oldPassword = "";
            this.newPassword = "";
            this.rePassword = "";
          }).catch(() => {
            this.loading = false;
            console.log('Failed')
          })
        } else {
          this.loading = false;
        }
      },
      togglePasswordForm() {
        this.isOpen = !this.isOpen;
      },
    },
    computed: {
      ...mapGetters(['current_user']),
      user: {
        get() {
          return this.current_user;
        },
        set(value) {
          this.setUser(value);
        }
      },
      passwordConfirmationRule() {
        return () => (this.newPassword === this.rePassword) || 'Password must match'
      }
    },
    
  }
</script>
