<template>
  <div>
    <v-container fluid>
      <h1>Dashboard</h1>
      <v-row class="mt-2">
        <v-col
          col="12"
          sm="4"
        >
          <v-card>
            <v-card-title class="text-h5">
              Projects
              <v-spacer></v-spacer>
              {{allProjects.length}}
            </v-card-title>
          </v-card>
        </v-col>
        <v-col
          col="12"
          sm="4"
        >
          <v-card>
            <v-card-title class="text-h5">
              Boxes
              <v-spacer></v-spacer>
              {{allBoxes.length}}
            </v-card-title>
          </v-card>
        </v-col>
        <v-col
          col="12"
          sm="4"
        >
          <v-card>
            <v-card-title class="text-h5">
              Products
              <v-spacer></v-spacer>
              {{allProducts.length}}
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <Customers />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import Customers from './Customers.vue'

  export default {
    name: 'Home',
    data () {
      return {

      }
    },
    methods: {
      ...mapActions(['fetchBoxes', 'fetchProductsByType', 'fetchProjects']),
    },
    computed: mapGetters(['allBoxes', 'allProducts', 'allProjects']),
    async created() {
      await this.fetchBoxes('box');
      await this.fetchProductsByType('product');
      await this.fetchProjects();
    },
    components: {
      Customers
    }
  }
</script>
