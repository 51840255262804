import axios from 'axios';

const state = {
    box: {},
    boxes: [],
    boxImageUploaded: ""
};

const getters = {
    box: (state) => state.box,
    allBoxes: (state) => state.boxes,
    boxImageName: (state) => state.boxImageUploaded
};

const actions = {
    async emptyBox({ commit }) {
        commit('setBox', {});
    },

    async fetchBox({ commit }, id) {
        const response = await axios.get(`/products/${id}`);
        
        commit('setBox', response.data);
    },

    async fetchBoxes({ commit }, type) {
        const response = await axios.get(`/products/type/${type}`);
        
        commit('setBoxes', response.data);
    },

    async addBox({ commit }, data) {
        const response = await axios.post('/products/', data);

        commit('newBox', response.data);
    },

    async updateBox({ commit }, data) {
        const response = await axios.put(`/products/${data._id}`, data);

        commit('updateBox', response.data);
    },

    async deleteBox({ commit }, id) {
        const response = await axios.delete(`/products/${id}`);
        if(response.data.productImageUrl !== undefined) {
            await axios.delete(`/uploads${response.data.productImageUrl}`);
        }

        commit('deleteBox', response.data);
    },

    async uploadBoxImage({ commit }, data) {
        const response = await axios.post('/uploads', data);
        commit('setBoxImageUploaded', response.data.imagePath);
    }
};

const mutations = {
    setBox: (state, box) => (state.box = box),
    setBoxes: (state, boxes) => (state.boxes = boxes),
    newBox: (state, box) => state.boxes.push(box),
    updateBox: (state, box) => {
        const index = state.boxes.findIndex(item => {
            return (box._id === item._id)
        })

        state.boxes.splice(index, 1, box);
    },
    deleteBox: (state, box) => {
        const index = state.boxes.findIndex(item => {
            return (box._id === item._id)
        })

        state.boxes.splice(index, 1);
    },
    setBoxImageUploaded: (state, newImage) => (state.boxImageUploaded = newImage)
};

export default {
    state,
    getters,
    actions,
    mutations
}