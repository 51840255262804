<template>
    <v-dialog
      v-model="isLoading"
      persistent
      fullscreen
    >
      <v-row align="center" justify="center" class="loading-wrapper">
        <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
        ></v-progress-circular>
      </v-row>
    </v-dialog>
</template>
<script>

  export default {
    name: 'LoadingDialog',
    props: {
      isLoading: { type: Boolean },
    },
  }
</script>
<style scoped>
.loading-wrapper {
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  margin: 0;
}
</style>