<template>
  <v-container fluid>
    <v-row 
      align="center"
      justify="space-between"
    >
      <v-col cols="auto" class="mr-auto">
        <h1 class="mb-5">Customers</h1>
      </v-col>
      <v-col cols="auto">
        <v-btn
          class="mb-5"
          color="grey darken-1"
          outlined 
          dark
          @click="openDialog()"
        >
          <v-icon
            left
            dark
          >
            mdi-pencil
          </v-icon>
          Add Customer
        </v-btn>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="table-search"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="allCustomers"
        :items-per-page="10"
        item-key="_id"
        show-select
        :search="search"
      >
        <template v-slot:[`item.name`]="{ item }">
          {{item.firstName}} {{item.lastName}}

        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="viewCustomer(item)"
          >
            mdi-eye
          </v-icon>
          <v-icon
            small
            class="mr-2"
            @click="editCustomer(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="removeCustomer(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <AddModal v-if="addDialog" :addDialog="addDialog" :closeDialog="closeDialog" :editedCustomerId="editedCustomerId"/>
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Do you want to delete this Customer?
        </v-card-title>

        <v-card-text>
          WARNING: Once you deleted this customer, you can no longer recover it later.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="gray darken-1"
            text
            @click="cancelDelete()"
          >
            Cancel
          </v-btn>

          <v-btn
            color="red darken-1"
            text
            @click="confirmDelete()"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import AddModal from '../components/AddModal.vue';

export default {
  name: 'Customers',
  data () {
    return {
      editedCustomerId: null,
      deleteCustomerId: null,
      addDialog: false,
      deleteDialog: false,
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Company', value: 'company' },
        { text: 'Address', value: 'address' },
        { text: 'Contact', value: 'contact' },
        { text: 'Email', value: 'email' },
        { text: 'Actions', value: 'actions' },
      ],
      selected: [],
      search: ''
    }
  },
  methods: {
    ...mapActions(['fetchCustomers', 'fetchCustomer', 'deleteCustomer']),
    async openDialog() {
      this.editedCustomerId = null;
      this.addDialog = true;
    },
    async viewCustomer(customer) {
      this.editedCustomerId = customer._id;
      this.viewDialog = true;
    },
    async editCustomer(customer) {
      await this.fetchCustomer(customer._id)
      this.editedCustomerId = customer._id;
      this.addDialog = true
    },
    async removeCustomer(customer) {
      this.deleteCustomerId = customer._id;
      this.deleteDialog = true;
    },
    cancelDelete() {
      this.deleteCustomerId = null
      this.deleteDialog = false;
    },
    async confirmDelete() {
      await this.deleteCustomer(this.deleteCustomerId);
      this.deleteDialog = false;
    },
    closeDialog() {
      this.addDialog = false
    },
    closeViewDialog() {
      this.viewDialog = false;
    }
  },
  computed: mapGetters(['allCustomers']),
  async created() {
    await this.fetchCustomers();
  },
  components: {
    AddModal
  }
}
</script>