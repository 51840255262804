import axios from 'axios';

const state = {
    product: {},
    products: [],
    productImageUploaded: ""
};

const getters = {
    product: (state) => state.product,
    allProducts: (state) => state.products,
    productImageName: (state) => state.productImageUploaded
};

const actions = {
    async emptyProduct({ commit }) {
        commit('setProduct', {});
    },

    async fetchProduct({ commit }, id) {
        const response = await axios.get(`/products/${id}`);
        
        commit('setProduct', response.data);
    },

    async fetchProducts({ commit }) {
        const response = await axios.get(`/products/`);
        
        commit('setProducts', response.data);
    },

    async fetchProductsByType({ commit }, type) {
        const response = await axios.get(`/products/type/${type}`);
        
        commit('setProducts', response.data);
    },

    async addProduct({ commit }, data) {
        const response = await axios.post('/products/', data);

        commit('newProduct', response.data);
    },

    async updateProduct({ commit }, data) {
        const response = await axios.put(`/products/${data._id}`, data);

        commit('updateProduct', response.data);
    },

    async deleteProduct({ commit }, id) {
        const response = await axios.delete(`/products/${id}`);
        if(response.data.productImageUrl !== undefined) {
            await axios.delete(`/uploads${response.data.productImageUrl}`);
        }
        
        commit('deleteProduct', response.data);
    },

    async uploadImage({ commit }, data) {
        const response = await axios.post('/uploads', data);
        commit('setProductImageUploaded', response.data.imagePath);
    }
};

const mutations = {
    setProduct: (state, product) => (state.product = product),
    setProducts: (state, products) => (state.products = products),
    newProduct: (state, product) => state.products.push(product),
    updateProduct: (state, product) => {
        const index = state.products.findIndex(item => {
            return (product._id === item._id)
        })

        state.products.splice(index, 1, product);
    },
    deleteProduct: (state, product) => {
        const index = state.products.findIndex(item => {
            return (product._id === item._id)
        })

        state.products.splice(index, 1);
    },
    setProductImageUploaded: (state, newImage) => (state.productImageUploaded = newImage)
};

export default {
    state,
    getters,
    actions,
    mutations
}