import axios from 'axios';

const state = {
    project: {},
    projects: [],
    selectedItems: [],
    selectedBox: {},
    projectImageUploaded: "",
    itemDefaultAttr: [],
    boxItems: [],
    formatProject: {},
    formatProjects: [],
    status: [
        {
          label: "Inquiry",
          value: "inquiry"
        },
        {
          label: "Ongoing Production",
          value: "ongoing production"
        },
        {
          label: "Delivered/Completed",
          value: "delivered/completed"
        }
      ],
};

const getters = {
    project: (state) => state.project,
    allProjects: (state) => state.projects,
    selectedBox: (state) => state.selectedBox,
    selectedItems: (state) => state.selectedItems,
    projectImageName: (state) => state.projectImageUploaded,
    itemDefaultAttr: (state) => state.itemDefaultAttr,
    boxItems: (state) => state.boxItems,
    formatProject: (state) => state.formatProject,
    formatProjects: (state) => state.formatProjects,
    status: (state) => state.status,
};

const actions = {
    async emptyProject({ commit }) {
        commit('setProject', {});
    },
    async fetchProject({ commit }, id) {
        const response = id? await axios.get(`/projects/${id}`) : {data: {}};
        commit('setProject', response.data);
    },

    async fetchProjects({ commit }) {
        const response = await axios.get('/projects');
        commit('setProjects', response.data);
    },

    addProjectItems({commit}, items) {
        commit('setItems', items)
    },

    addSelectedBox({commit}, box) {
        commit('setBox', box)
    },

    addBoxItems({commit}, items) {
        commit('setBoxItem', items)
    },

    async addProject({commit}, data) {
        const response = await axios.post('/projects', data);
        if(response.data.hasError === undefined) {
            commit('newProject', response.data);
        }

        return response.data;
        
    },

    async updateProject({ commit }, data) {
        const response = await axios.put(`/projects/${data._id}`, data);
        if(response.data.hasError === undefined) {
            commit('updateProject', response.data);
        }

        return response.data;
    },

    async deleteProject({ commit }, id) {
        const response = await axios.delete(`/projects/${id}`);
        if(response.data.projectBoxImg !== undefined) {
            await axios.delete(`/uploads${response.data.projectBoxImg}`);
        }
        
        commit('deleteProject', response.data);
    },

    async uploadProjectImage({ commit }, data) {
        const response = await axios.post('/uploads', data);
        commit('setProjectImageUploaded', response.data.imagePath);
    },

    async deleteProjectImage({ commit }, id) {
        await axios.delete(`/uploads${id}`);
        commit('setProjectImageUploaded', "");
    },

    async reformatData({ commit }, id) {
        const response = id? await axios.get(`/projects/csv/${id}`) : {data: {}};

        commit('setFormatProject', response.data)
    },

    async setProjects({ commit }, data) {
        // const response = await axios.post(`/projects/csv`, data);
        // commit('setFormatProjects', response.data)
        
        let newData = [];
        data.map(async(item) => {
            const response = await axios.get(`/projects/csv/${item._id}`);
            newData.push(response.data);
            if(data.length === newData.length) {
                commit('setFormatProjects', newData);
            }
        });
        
        // commit('setFormatProjects', newArray);
        
    },

    async fetchItemDefault({commit}, data) {
        let newItemAttr = [];
        let _URL = window.URL || window.webkitURL;
        data.map((item) => {
            fetch(`${process.env.VUE_APP_API_BASE_URL}/uploads${item.productImageUrl}`)
            .then(response => {
                return response.blob();
            })
            .then(blob => {
                let img = new Image();
                img.onload = function() {
                    let ratio = Math.min(150 / img.width, 150 / img.height);
                    let w = img.width*ratio;
                    let h = img.height*ratio;
                    let newAttr = {
                        _id: item._id,
                        name: item.name,
                        count: parseInt(item.selectedCount) ? parseInt(item.count) - parseInt(item.selectedCount): parseInt(item.count),
                        productImageUrl: `${process.env.VUE_APP_API_BASE_URL}/uploads${item.productImageUrl}`,
                        handlers: ["r", "rb", "b", "lb", "l", "lt", "t", "rt"],
                        height: h,
                        width: w,
                        maxW: (img.width * 2),
                        maxH: (img.height * 2),
                        minW: 150 / 2,
                        minH: h / 2,
                        fit: true,
                        event: "",
                        class: `drag-container-${item._id}`,
                        dragSelector: `.drag-container-${item._id}`,
                        list: 1
                    }
                    newItemAttr.push(newAttr);
                };
                img.onerror = function() {
                    // console.log( "not a valid file: " + blob.type);
                };
                img.src = _URL.createObjectURL(blob);
            })
            
        })
        commit('setItemDefaultAttr', newItemAttr);
    }
};

const mutations = {
    setProject: (state, project) => (state.project = project),
    setProjects: (state, projects) => (state.projects = projects),
    setItems: (state, items) => (state.selectedItems = items),
    setBox: (state, box) => (state.selectedBox = box),
    setBoxItem: (state, boxItems) => (state.boxItems = boxItems),
    setFormatProject: (state, formatProject) => (state.formatProject = formatProject),
    setFormatProjects: (state, formatProjects) => (state.formatProjects = formatProjects),
    newProject: (state, project) => state.projects.push(project),
    updateProject: (state, project) => {
        const index = state.projects.findIndex(item => {
            return (project._id === item._id)
        })

        state.projects.splice(index, 1, project);
    },
    deleteProject: (state, project) => {
        const index = state.projects.findIndex(item => {
            return (project._id === item._id)
        })

        state.projects.splice(index, 1);
    },
    setProjectImageUploaded: (state, newImage) => (state.projectImageUploaded = newImage),
    setItemDefaultAttr: (state, newItemsAttr) => (state.itemDefaultAttr = newItemsAttr)
};

export default {
    state,
    getters,
    actions,
    mutations
}