import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from "vuex-persistedstate";
import products from './modules/products'
import boxes from './modules/boxes'
import projects from './modules/projects'
import customers from './modules/customers'
import users from './modules/users'
import auth from './modules/auth'
import shared from './modules/shared'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    products,
    boxes,
    projects,
    customers,
    users,
    auth,
    shared
  },
  // plugins: [createPersistedState()]
})
