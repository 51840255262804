<template>
  <v-row>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="1200px"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="onClose()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{editedItemId !== null? 'Edit Product': 'Add New Product'}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="onSave()"
            >
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container fluid class="mt-3">
            <v-form>
              <v-row>
                <v-col
                  cols="12"
                  sm="9"
                >
                  <v-row>
                    <v-col
                      cols="12">
                      <v-text-field
                        v-model="product.name"
                        label="Product Name*"
                        outlined
                        dense
                        required
                      ></v-text-field>
                      
                    </v-col>
                    <v-col
                      cols="12">
                      <v-textarea
                        v-model="product.description"
                        label="Description*"
                        rows="3"
                        outlined
                        dense
                        required
                      ></v-textarea>
                      
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="product.supplierName"
                        label="Supplier Name*"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="product.qty"
                        label="Current Inventory*"
                        type="number"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="product.cop"
                        label="Cost of Product/Service*"
                        type="number"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        v-model="product.srp"
                        label="SRP*"
                        type="number"
                        outlined
                        dense
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  sm="3"
                >
                  <v-row>
                    <v-col
                      cols="12">
                      <v-file-input
                        prepend-icon="mdi-camera"
                        name="file"
                        accept="image/*"
                        label="Product Image"
                        v-model="productImage"
                        outlined
                        dense
                      ></v-file-input>
                      <v-img
                        :src="imgPath(product.productImageUrl)"
                        aspect-ratio="1.7"
                        v-if="!!editedItemId"
                        class="mt-5"
                      ></v-img>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <h3>Summary:</h3>
                      <v-list dense>
                        <v-list-item>
                          <v-list-item-content>Product Name:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ product.name }}
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>Description:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ product.description }}
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>Supplier Name:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ product.supplierName }}
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>Quantity:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ product.qty }}
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>Cost of product/service:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ product.cop }}
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>SRP:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ product.srp }}
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>Profit:</v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ (product.srp - product.cop).toFixed(2) || product.profit }}
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-content>Mark up (%):</v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ Math.round(((product.srp - product.cop) / product.cop)*100) || product.markup }}
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-row>
                    
                  </v-row>
                </v-col>
                
              </v-row>
            </v-form>
            <small>*indicates required field</small>
          </v-container>
        </v-card-text>
      </v-card>
      <LoadingDialog :isLoading="isLoading" />
    </v-dialog>
  </v-row>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import LoadingDialog from './shared/LoadingDialog.vue';

  export default {
    name: 'AddItemModal',
    props: {
      openDialog: { type: Function },
      closeDialog: { type: Function },
      editedItemId: { type: String },
      dialog: { type: Boolean },
    },
    data () {
      return {
        productImage: null,
        isLoading: false,
        // product: {}
      }
    },
    methods: {
      ...mapActions(['addProduct', 'uploadImage', 'fetchProduct', 'updateProduct']),
      async onSave() {
        this.isLoading = true;
        this.product.type = 'product';
        if(this.productImage !== null) {
          let productImageName = await this.upload(this.productImage);
          this.product.productImageUrl = productImageName;
        }

        if(this.editedItemId !== null){
          this.updateProduct({...this.product})
        } else {
          this.addProduct({...this.product})
        }
        
        this.productImage = null
        this.isLoading = false;
        this.closeDialog();
      },
      async upload(file) {
        let formData = new FormData();

        formData.append('file', file);
        await this.uploadImage(formData);
        return await this.productImageName;
      },
      onClose() {
        this.productImage = null
        this.closeDialog();
      },
      imgPath(name) {
        if(name)
          return `${process.env.VUE_APP_API_BASE_URL}/uploads${name}`;
      },
    },
    computed: {
      ...mapGetters(['productImageName', 'product']),

    },
    components: {
      LoadingDialog,
    }
  }
</script>