const state = {
    snackbar: {
        isOpen: false,
        text: "",
        status: "success"
    },
};

const getters = {
    snackbar: (state) => state.snackbar,
};

const actions = {
    async openSnackbar({ commit }, data) {
    data.isOpen = true;
    commit('setSnackbar', data)
    },
};

const mutations = {
    setSnackbar: (state, data) => (state.snackbar = data),
};

export default {
    state,
    getters,
    actions,
    mutations
}