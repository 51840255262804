<template>
  <v-row>
    <v-col>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card
          class="mx-auto my-12"
          elevation="2"
          max-width="374"
        >
          <v-card-title>
            <img alt="Vue logo" class="logo" src="../assets/logov2.png">
          </v-card-title>
          <v-card-text class="px-4">
            <v-text-field
              v-model="user.firstName"
              label="First Name"
              :rules="rules.required"
              outlined
              dense
              required
            ></v-text-field>
            <v-text-field
              v-model="user.lastName"
              label="Last Name"
              :rules="rules.required"
              outlined
              dense
              required
            ></v-text-field>
            <v-text-field
              v-model="user.email"
              type="email"
              label="Email"
              :rules="rules.email"
              outlined
              dense
              required
            ></v-text-field>
            <v-text-field
              v-model="user.username"
              label="Username"
              :rules="rules.username"
              outlined
              dense
              required
            ></v-text-field>
            <v-text-field
              v-model="user.password"
              label="Password"
              type="password"
              :rules="rules.password"
              outlined
              dense
              required
            ></v-text-field>
            <v-text-field
              v-model="rePassword"
              label="Re-enter Password"
              type="password"
              :rules="[passwordConfirmationRule]"
              outlined
              dense
              required
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="justify-center px-4">
            <v-row>
              <v-col sm="12">
                <v-btn
                  :disabled="!valid"
                  block
                  color="primary mb-2"
                  @click="signup"
                  :loading="loading"
                >
                  Sign Up
                </v-btn>
              </v-col>
              <v-col sm="12">
                <v-btn
                  block
                  color="mb-2"
                  @click="goto('/login')"
                >
                  Already have an account!
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col> 
  </v-row>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: "Signup",
  data () {
    return {
      valid: false,
      user: {},
      loading: false,
      rePassword: "",
      rules: {
        required: [
          v => !!v || 'This field is required',
        ],
        username: [
          v => !!v || 'Username is required',
        ],
        password: [
          v => !!v || 'Password is required',
        ],
        email: [
          v => !!v || 'E-mail is required',
          v => /.+@.+/.test(v) || 'E-mail must be valid',
        ]
      }
    }
  },
  methods: {
    ...mapActions(['signUp']),
    async signup() {
      this.loading = true;
      await this.$refs.form.validate()
      if(this.valid) {
        await this.signUp({...this.user}).then(() => {
          this.$router.push({ path: '/dashboard' })
          this.loading = false;
        }).catch(() => {
          this.loading = false;
          console.log('Failed')
        })
      } else {
        this.loading = false;
      }
    },

    async goto(path) {
      this.$router.push({path}).catch(err => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          // But print any other errors to the console
          // logError(err);
        }
      });
    },
  },
  computed: {
    passwordConfirmationRule() {
      return () => (this.user.password === this.rePassword) || 'Password must match'
    }
  }
}
</script>