<template>
  <v-row>
    <v-dialog
      v-model="viewDialog"
      persistent
      fullscreen
      hide-overlay
    >
      <v-card>
        <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="closeViewDialog()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{box.name}}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
        <v-card-text v-if="Object.keys(project).length">
          <v-row>
            <v-col
              cols="12"
              sm="8"
            >
              <v-container
                @drop.prevent="onDrop($event, 2)"
                @dragenter.prevent
                @dragover.prevent
              >
                <v-img
                  contain
                  :src="imgPath(project.projectBoxImg)"
                  aspect-ratio="1.7"
                  id="boxImage"
                >
                </v-img>
                <v-row 
                  class="mt-5 "
                  justify="space-between"
                >
                  <v-btn
                    elevation="2"
                    @click="download()"
                    color="primary"
                  >
                  Download Image
                  </v-btn>
                  <v-btn 
                    elevation="2"
                    @click="downloadCSVData()"
                    color="success"
                  >
                  Export to CSV
                  </v-btn>
                </v-row>
                
                
              </v-container>
            </v-col>
            <v-col
              cols="12"
              sm="4"
            >
              <v-card class="mx-1 my-5">
                <v-card-title class="subheading font-weight-bold">
                  Project Information
                </v-card-title>

                <v-divider></v-divider>

                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>Name</v-list-item-content>
                    <v-list-item-content class="align-end justify-end">
                      {{ project.name }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>Cost per box:</v-list-item-content>
                    <v-list-item-content class="align-end justify-end">
                      {{ project.totalCost }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>SRP per box;</v-list-item-content>
                    <v-list-item-content class="align-end justify-end">
                      {{ project.totalSrp }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>Profit per box:</v-list-item-content>
                    <v-list-item-content class="align-end justify-end">
                      {{ project.totalProfit}}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>Markup Per box(%):</v-list-item-content>
                    <v-list-item-content class="align-end justify-end">
                      {{ project.totalMarkup }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>Number of Box:</v-list-item-content>
                    <v-list-item-content class="align-end justify-end">
                      {{ project.count }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>Cost of project:</v-list-item-content>
                    <v-list-item-content class="align-end justify-end">
                      {{ project.totalCost * project.count }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>SRP of project:</v-list-item-content>
                    <v-list-item-content class="align-end justify-end">
                      {{ project.totalSrp * project.count }}
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>Profit of project:</v-list-item-content>
                    <v-list-item-content class="align-end justify-end">
                      {{ project.totalProfit * project.count }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-card class="mx-1 my-5">
                <v-card-title class="subheading font-weight-bold">
                  Customer 
                </v-card-title>

                <v-divider></v-divider>

                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>{{ project.customer.completeName }}</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-card class="mx-1 my-5">
                <v-card-title class="subheading font-weight-bold">
                  Selected Box 
                </v-card-title>

                <v-divider></v-divider>

                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>{{ project.selectedBox.name }}</v-list-item-content>
                    <v-list-item-content class="align-end justify-end">
                      1
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-card v-if="selectedItems.length" class="mx-1 my-5">
                <v-card-title class="subheading font-weight-bold">
                  Selected Products
                </v-card-title>

                <v-divider></v-divider>

                <v-list dense>
                  <v-list-item
                    v-for="item in project.selectedProducts"
                    :key="item._id"
                  >
                    <v-list-item-content>{{ item.name }}</v-list-item-content>
                    <v-list-item-action class="align-end justify-end">
                      {{item.count}}
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import domtoimage from 'dom-to-image';
  import { ExportToCsv } from 'export-to-csv';

  export default {
    name: 'ViewProjectModal',
    components: { },
    props: {
      openViewDialog: { type: Function },
      closeViewDialog: { type: Function },
      editedProjectId: { type: String },
      viewDialog: { type: Boolean },
    },
    data () {
      return {
        boxImage: null,
        output: "",
        options: { 
          filename: 'export-project',
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true, 
          useTextFile: false,
          useBom: true,
          headers: [
            // 'Image',
            'Customer',
            'Box Name',
            'Contents',
            'Product Description',
            'Supplier Name',
            'Due Date',
            'Status',
            'Cost/Item',
            'Total Cost/Box',
            'SRP/Item',
            'Total SRP/box',
            'Total Profit/Box',
            'Mark up/Item',
            'Mark up/Box'
          ],
        },
      }
    },
    methods: {
      ...mapActions(['addBox', 'uploadBoxImage', 'addBoxItems', 'updateBox', 'fetchProduct']),
      imgPath(name) {
        if(name && this.boxImage == null)
          return `${process.env.VUE_APP_API_BASE_URL}/uploads${name}`;
      },

      async download() {
        let node = document.getElementById('boxImage');
        await domtoimage.toJpeg(node)
          .then( async (dataUrl) => {
            let link = document.createElement('a');
            link.download = `box-image-${this.project.name}.png`;
            link.href = dataUrl;
            link.click();
          })
          .catch(function (error) {
            console.error('oops, something went wrong!', error);
          });
      },

      downloadCSVData() {
        const csvExporter = new ExportToCsv(this.options);
        csvExporter.generateCsv([this.formatProject]);
      }
    },
    computed: {
      ...mapGetters(['project', 'formatProject', 'box', 'product', 'selectedItems', 'boxImageName', 'itemDefaultAttr']),
      
    },
  }
</script>
<style>
  .resizable {
    position: absolute !important;
    width: 150px;
    height: 150px;
    padding: 0;
    cursor: pointer;
  }
</style>