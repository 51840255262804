<template>
  <v-container fluid>
    <v-row
      align="center"
      justify="space-between"
      
    >
      <v-col cols="auto" class="mr-auto">
        <h1 class="mb-5">Projects</h1>
      </v-col>
      <v-col cols="auto">
        <v-btn
          class="mb-5 mr-2"
          color="grey darken-1"
          outlined
          :disabled="!selected.length"
          @click="confirmDownload()"
        >
          <v-icon
            left
            dark
          >
            mdi-download
          </v-icon>
          Export to CSV
        </v-btn>
        <v-btn
          class="mb-5"
          color="grey darken-1"
          outlined
          @click="openDialog()"
        >
          <v-icon
            left
            dark
          >
            mdi-pencil
          </v-icon>
          Add Project
        </v-btn>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="table-search"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="allProjects"
        :items-per-page="10"
        item-key="_id"
        show-select
        :search="search"
        @item-selected="toggleSelect()"
        @toggle-select-all="toggleSelectAll()"
      >
        <template v-slot:[`item.customer`]="{ item }">
          <div>{{ `${item.customer.firstName} ${item.customer.lastName}` }}</div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-edit-dialog
            :return-value.sync="item.status"
            large
            persistent
            @save="saveStatus(item)"
            @cancel="cancel"
          >
            <div>{{ item.status }}</div>
            <template v-slot:input>
              <div class="mt-4 mb-4 text-h6">
                Update Status
              </div>
              <!-- <v-text-field
                v-model="item.status"
                :rules="[max25chars]"
                label="Edit"
                single-line
                counter
                autofocus
              ></v-text-field> -->
              <v-autocomplete
                v-model="item.status"
                label="Edit"
                item-text="label"
                item-value="value"
                :items="status"
                chips
                persistent-hint
                clearable
                outlined
                dense
                small-chips
                required
              >
              </v-autocomplete>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="viewProject(item)"
          >
            mdi-eye
          </v-icon>
          <v-icon
            small
            class="mr-2"
            @click="editProject(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="removeProject(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <AddProjectModal v-if="addDialog" :addDialog="addDialog" :closeDialog="closeDialog" :editedProjectId="editedProjectId"/>
    <ViewProjectModal v-if="viewDialog" :viewDialog="viewDialog" :closeViewDialog="closeViewDialog" :editedProjectId="editedProjectId"/>
    <v-dialog
      v-model="deleteDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Do you want to delete this Project?
        </v-card-title>

        <v-card-text>
          WARNING: Once you deleted this project, you can no longer recover it later.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="gray darken-1"
            text
            @click="cancelDelete()"
          >
            Cancel
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDelete()"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="csvDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Attention!
        </v-card-title>

        <v-card-text>
          Your about to download a csv file. Do you wish to continue?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="gray darken-1"
            text
            @click="() => csvDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="downloadCSVData()"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';
  import AddProjectModal from '../components/AddProjectModal.vue';
  import ViewProjectModal from '../components/ViewProjectModal.vue';
  import { ExportToCsv } from 'export-to-csv';

  export default {
    name: 'Projects',
    data () {
      return {
        headers: [
          {
            text: 'Client Name',
            align: 'start',
            value: 'customer',
          },
          {
            text: 'Box Name',
            align: 'start',
            value: 'name',
          },
          { text: 'Due Date', value: 'dueDate' },
          { text: 'Quantity', value: 'count' },
          { text: 'Status', value: 'status' },
          { text: 'Actions', value: 'actions' },
        ],
        editedProjectId: null,
        deleteProjectId: null,
        addDialog: false,
        viewDialog: false,
        deleteDialog: false,
        csvDialog: false,
        options: { 
          filename: 'export-project',
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true, 
          useTextFile: false,
          useBom: true,
          headers: [
            // 'Image',
            'Customer',
            'Box Name',
            'Contents',
            'Product Description',
            'Supplier Name',
            'Due Date',
            'Status',
            'Cost/Item',
            'Total Cost/Box',
            'SRP/Item',
            'Total SRP/box',
            'Total Profit/Box',
            'Mark up/Item',
            'Mark up/Box'
          ],
        },
        selected: [],
        search: '',
      }
    },
    methods: {
      ...mapActions([
        'emptyProject', 
        'fetchProject', 
        'fetchProjects',
        'fetchProductsByType', 
        'fetchBoxes', 
        'fetchProject', 
        'addProject', 
        'addProjectItems', 
        'addSelectedBox', 
        'addBoxItems', 
        'deleteProject', 
        'reformatData', 
        'setProjects',
        'setSelectedCustomer',
        'updateProject',
        'openSnackbar'
        ]),
      async editProject(project) {
        await this.addProjectItems(project.selectedProducts)
        await this.addSelectedBox(project.selectedBox)
        await this.fetchProject(project._id);
        await this.setSelectedCustomer(project.customer)
        this.editedProjectId = project._id;
        this.addDialog = true;
      },
      async viewProject(project) {
        await this.addProjectItems(project.selectedProducts)
        await this.addSelectedBox(project.selectedBox)
        await this.fetchProject(project._id);
        await this.reformatData(project._id);
        this.editedProjectId = project._id;
        this.viewDialog = true;
      },
      async openDialog() {
        await this.addProjectItems([])
        await this.addSelectedBox({})
        await this.emptyProject();
        await this.setSelectedCustomer([])
        this.editedProjectId = null;
        this.addDialog = true;
      },
      closeDialog() {
        this.addBoxItems([]);
        this.addDialog = false;
      },
      openViewDialog() {
        this.editedBoxId = null
        this.viewDialog = true
      },
      closeViewDialog() {
        this.viewDialog = false
      },
      removeProject(box) {
        this.deleteProjectId = box._id
        this.deleteDialog = true;
      },
      cancelDelete() {
        this.deleteBoxId = null
        this.deleteDialog = false;
      },
      async confirmDelete() {
        await this.deleteProject(this.deleteProjectId);
        this.deleteDialog = false;
      },
      saveStatus(item) {
        this.updateProject({...item})
            .then((res) => {
              if(res.hasError === undefined){
                this.openSnackbar({text: `${item.name} is updated`, status: "success"});
              } else {
                this.openSnackbar({text: res.message, status: "error"});
              }
              this.isLoading = false;
            });
      },
      cancel() {
        // this.openSnackbar({text: "No changes", status: "error"});
      },
      async toggleSelect() {
      },
      async toggleSelectAll() {
      },
      async confirmDownload() {
        this.csvDialog = true;
        if(this.selected.length) {
          await this.setProjects(this.selected);
        }
      },
      async downloadCSVData() {
        let formatProjects = await this.formatProjects;
        if(formatProjects.length) {
          const csvExporter = new ExportToCsv(this.options);
          csvExporter.generateCsv(formatProjects);
        }
        this.csvDialog = false;
      },
    },
    computed: {
      ...mapGetters(['allBoxes', 'allProducts', 'allProjects', 'formatProjects', 'status']),
      hasItem() {
        const box_count = this.allBoxes.length;
        const product_count = this.allProducts.length;
        return !!box_count && !!product_count;
      }
    },
    async created() {
      await this.fetchProjects();
      await this.fetchProductsByType('product');
      await this.fetchBoxes('box');
    },
    components: {
      AddProjectModal,
      ViewProjectModal
    }
  }
</script>
