<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      app
      v-if="hasNav"
    >
      <v-list light>
        <v-list-item
          v-for="item in sideMenu"
          :key="item.title"
          link
          v-on:click = "goto(item.path)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block dark color="primary" @click="() => {logoutDialog = true}">
            <v-icon left>
              mdi-logout
            </v-icon>
            Sign Out
          </v-btn>
        </div>
      </template>
      <v-dialog
        v-model="logoutDialog"
        max-width="290"
      >
        <v-card>
          <v-card-title class="text-h6">
            Do you want to Sign Out?
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="gray darken-1"
              text
              @click="() => {logoutDialog = false}"
            >
              No
            </v-btn>

            <v-btn
              color="red darken-1"
              text
              @click="confirmSignOut()"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-navigation-drawer>
    <v-app-bar app v-if="hasNav">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Qurated Box App</v-toolbar-title>

      <v-spacer></v-spacer>
        <v-btn
          small
          color="primary"
          fab
          dark
          @click="goto('profile')"
        >
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
      <SnackbarDialog />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'App',
  metaInfo() {
    return { 
        title: "Qurate - Build your box",
        meta: [
            { name: 'description', content:  'Build your box'},
            { property: 'og:title', content: "Build your box"},
            { property: 'og:site_name', content: 'Qurate'},
            {property: 'og:type', content: 'website'},    
            {name: 'robots', content: 'index,follow'} 
        ]
    }
  },
  data: () => ({
    drawer: null,
    items: [
      { title: 'Option' },
      { title: 'Logout' },
    ],

    sideMenu: [
      {
        icon: 'mdi-home',
        title: 'Dashboard',
        path: '/dashboard'
      },
      {
        icon: 'mdi-archive-check',
        title: 'Projects',
        path: '/projects'
      },
      {
        icon: 'mdi-widgets',
        title: 'Boxes',
        path: '/boxes'
      },
      {
        icon: 'mdi-clipboard-list',
        title: 'Products',
        path: '/products'
      }
    ],
    logoutDialog: false,
  }),
  methods: {
    ...mapActions(['signOut']),
    async goto(path) {
      this.$router.push({path}).catch(err => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          // But print any other errors to the console
          // logError(err);
        }
      });
    },

    confirmSignOut() {
      this.signOut().then(() => {
        this.$router.replace({
          path: '/login'
        })
      })
      this.logoutDialog = false;
    },
  },
  computed: {
    ...mapGetters(['token']),
    currentRouteName() {
      return this.$route.name;
    },
    hasNav() {
      return this.currentRouteName !== 'Login' && this.currentRouteName !== 'Signup';
    }
  },
};
</script>
<style>
.table-search {
  max-width: 40% !important;
}

.logo {
  width: 50%;
  margin: 0 auto;
}
</style>