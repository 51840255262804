<template>
  <v-row>
    <v-col>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <v-card
          class="mx-auto my-12"
          elevation="2"
          max-width="374"
        >
          <v-card-title>
            <img alt="Vue logo" class="logo" src="../assets/logov2.png">
          </v-card-title>
          <v-card-text class="px-4">
            <v-text-field
              v-model="user.username"
              label="Username"
              :rules="rules.username"
              outlined
              dense
              required
            ></v-text-field>

            <v-text-field
              v-model="user.password"
              label="Password"
              type="password"
              :rules="rules.password"
              outlined
              dense
              required
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="px-4">
            <v-row>
              <v-col sm="12">
                <v-btn
                  :loading="loading"
                  :disabled="!valid"
                  block
                  color="primary mb-2"
                  @click="login"
                >
                  Login
                </v-btn>
              </v-col>
              <v-col sm="12">
                <v-btn
                  block
                  color="mb-2"
                  @click="goto('/signup')"
                >
                  Make an account?
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col> 
  </v-row>
</template>
<script lang="ts">

import { mapGetters, mapActions } from 'vuex';

export default {
  name: "Login",
  data () {
    return {
      valid: true,
      user: {},
      loading: false,
      rules: {
        username: [
          v => !!v || 'Username is required',
        ],
        password: [
          v => !!v || 'Password is required',
        ]
      }
    }
  },
  methods: {
    ...mapActions(['signIn']),
    async login() {
      this.loading = true;
      await this.$refs.form.validate()
      if(this.valid) {
        await this.signIn({...this.user}).then(() => {
          this.$router.push({ path: '/dashboard' })
          this.loading = false;
        }).catch(() => {
          this.loading = false;
          console.log('Failed')
        })
      } else {
        this.loading = false;
      }
    },

    async goto(path) {
      this.$router.push({path}).catch(err => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          // But print any other errors to the console
          // logError(err);
        }
      });
    },
  },
  computed: mapGetters(['authenticated', 'current_user']),
}
</script>