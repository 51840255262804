<template>
  <v-container fluid>
    <v-row
      align="center"
      justify="space-between"
      
    >
      <v-col cols="auto" class="mr-auto">
        <h1 class="mb-5">Boxes</h1>
      </v-col>
      <v-col cols="auto">
        
        <v-btn
          class="mb-5"
          color="grey darken-1"
          outlined 
          dark
          @click="openDialog()"
        >
          <v-icon
            left
            dark
          >
            mdi-pencil
          </v-icon>
          Add Box
        </v-btn>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="table-search"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="allBoxes"
        :items-per-page="10"
        :search="search"
      >
          <template v-slot:[`item.boxImageUrl`]="{ item }">
            <v-img
              :src="imgPath(item.boxImageUrl)"
              aspect-ratio="1.7"
            ></v-img>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="viewItem(item)"
            >
              mdi-eye
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
      </v-data-table>
    </v-card>
    
    <AddBoxModal :dialog="dialog" :openDialog="openDialog" :closeDialog="closeDialog" :editedItemId="editedItemId"/>
    <ViewBoxModal :viewDialog="viewDialog" :openViewDialog="openViewDialog" :closeViewDialog="closeViewDialog" :editedItemId="editedItemId"/>
    <v-dialog
      v-model="deleteDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Do you want to delete this box?
        </v-card-title>

        <v-card-text>
          WARNING: Once you deleted this box, you can no longer recover it later.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="gray darken-1"
            text
            @click="cancelDelete()"
          >
            Cancel
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDelete()"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';
  import AddBoxModal from '../components/AddBoxModal.vue';
  import ViewBoxModal from '../components/ViewBoxModal.vue';

  export default {
    name: 'Items',
    data () {
      return {
        headers: [
          // {
          //   text: 'Image',
          //   align: 'start',
          //   value: 'productImageUrl',
          // },
          { text: 'Box Name', value: 'name'},
          { text: 'Quantity', value: 'qty', width: "10%" },
          { text: 'Supplier', value: 'supplierName', width: "15%" },
          { text: 'Cost of product/service', value: 'cop', width: "20%" },
          { text: 'SRP', value: 'srp', width: "8%" },
          { text: 'Profit', value: 'profit', width: "8%" },
          { text: 'Mark up (%)', value: 'markup', width: "12%" },
          { text: 'Actions', value: 'actions', sortable: false, width: "10%" },
        ],
        editedItemId: null,
        deleteItemId: null,
        dialog: false,
        viewDialog: false,
        deleteDialog: false,
        search: '',
      }
    },
    methods: {
      ...mapActions(['fetchBoxes', 'fetchBox', 'deleteBox', 'emptyBox']),
      imgPath(name) {
        return `${process.env.VUE_APP_API_BASE_URL}/uploads${name}`;
      },
      async editItem(item) {
        // this.editedIndex = this.allProducts.indexOf(item)
        // this.editedItem = Object.assign({}, item)
        await this.fetchBox(item._id);
        this.editedItemId = item._id
        this.dialog = true
      },
      async viewItem(item) {
        await this.fetchBox(item._id);
        this.editedItemId = item._id
        this.viewDialog = true
      },
      async openDialog() {
        await this.emptyBox();
        this.editedItemId = null
        this.dialog = true
      },
      closeDialog() {
        this.dialog = false
      },
      openViewDialog() {
        this.editedItemId = null
        this.viewDialog = true
      },
      closeViewDialog() {
        this.viewDialog = false
      },
      deleteItem(item) {
        this.deleteItemId = item._id
        this.deleteDialog = true;
      },
      cancelDelete() {
        this.deleteItemId = null
        this.deleteDialog = false;
      },
      async confirmDelete() {
        await this.deleteBox(this.deleteItemId);
        // this.deleteItemId = null;
        this.deleteDialog = false;
      }
    },
    computed: mapGetters(['allBoxes']),
    async created() {
      await this.fetchBoxes('box');
    },
    components: {
      AddBoxModal,
      ViewBoxModal
    }
  }
</script>
