<template>
    <v-snackbar
      v-model="snackbar.isOpen"
      :timeout="timeout"
      :color="snackbar.status"
      right
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar.isOpen = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
</template>
<script>
  import { mapGetters } from 'vuex';
  export default {
    name: 'SnackbarDialog',
    data: () => ({
        timeout: 10000,
    }),
    computed: {
        ...mapGetters(['snackbar']),
    },
  }
</script>
<style scoped>
</style>