<template>
  <v-row>
    <v-dialog
      v-model="addDialog"
      persistent
      fullscreen
      hide-overlay
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="onClose()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Add New Project</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              v-if='e1 == 2'
              dark
              text
              @click="e1 = 1"
            >
              Back
            </v-btn>
            <v-btn
              v-if='e1 == 2'
              dark
              text
              @click="onSave()"
            >
              Save
            </v-btn>
            <v-btn
              v-if='e1 != 2'
              dark
              text
              :disabled="!productValid"
              @click="onContinue()"
            >
              Continue
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-stepper v-model="e1" class="mt-5">
            <v-stepper-header>
              <v-stepper-step
                :complete="e1 > 1"
                step="1"
              >
                Fill up all fields
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
              >
                Arrange Items
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <v-form>
                  <v-row class="mt-5">
                    <v-col
                      cols="12"
                      order="first"
                    >
                      <v-row justify="space-between">
                        <v-col
                          cols="12"
                          sm="3"
                        >
                          <v-text-field
                            v-model="project.name"
                            label="Project Name*"
                            outlined
                            dense
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="3"
                        >
                          <v-autocomplete
                            v-model="_selectedCustomer"
                            label="Customer"
                            placeholder="Please select customer"
                            item-text="completeName"
                            item-value="_id"
                            :items="allCustomers"
                            chips
                            hint="Please select a customer for this project."
                            persistent-hint
                            return-object
                            clearable
                            outlined
                            dense
                            small-chips
                          >
                          </v-autocomplete>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="2"
                        >
                          <v-text-field
                            v-model="project.count"
                            label="Quantity*"
                            type="number"
                            outlined
                            dense
                            required
                            hint="Please fill out the quantity of this project."
                            persistent-hint
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="2"
                        >
                          <!-- <v-text-field
                            v-model="project.dueDate"
                            label="Due Date*"
                            outlined
                            dense
                            required
                            persistent-hint
                          ></v-text-field> -->
                          <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="date"
                                label="Due Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                outlined
                                dense
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="date"
                              @input="menu = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="2"
                        >
                          <v-autocomplete
                            v-model="project.status"
                            label="Status*"
                            placeholder="Ex. Inquiry"
                            item-text="label"
                            item-value="value"
                            :items="status"
                            chips
                            persistent-hint
                            clearable
                            outlined
                            dense
                            small-chips
                            required
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-row justify="space-between">
                        <v-col
                          cols="12"
                          sm="6"
                        >
                          <v-autocomplete
                            v-model="chosenBox"
                            item-text="name"
                            item-value="_id"
                            :items="allBoxes"
                            chips
                            hint="Please select box type for this box."
                            persistent-hint
                            return-object
                            clearable
                            label="Box Type"
                            outlined
                            dense
                            small-chips
                            @change="onChangeBox($event)"
                          >
                            <!-- <template v-slot:selection="{ item }">
                              <v-chip text-color="white" small
                              >{{ item.name }}</v-chip>
                            </template>
                            <template v-slot:item="{ item, attrs, on }">
                              <v-list-item v-on="on" v-bind="attrs">
                                <v-list-item-action>
                                  <v-checkbox></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ item.name }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template> -->
                          </v-autocomplete>
                          <v-card v-if="selectedBox !== null && Object.keys(chosenBox).length" class="mx-1 my-5">
                            <v-card-title class="subheading font-weight-bold">
                              Selected Box 
                            </v-card-title>

                            <v-divider></v-divider>

                            <v-list dense>
                              <v-list-item>
                                <v-list-item-content>{{ chosenBox.name }}</v-list-item-content>
                                <v-list-item-content class="align-end justify-end">
                                  1
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="6"
                        >
                          <v-autocomplete
                            v-model="selectedProducts"
                            item-text="name"
                            item-value="_id"
                            :items="allProducts"
                            chips multiple
                            hint="Please select products for this box."
                            persistent-hint
                            return-object
                            clearable
                            label="Products"
                            outlined
                            dense
                            small-chips
                            @change="onChangeItems($event)"
                          >
                            <!-- <template v-slot:selection="{ item }">
                              <v-chip text-color="white" small
                              >{{ item.name }}</v-chip>
                            </template>
                            <template v-slot:item="{ item, attrs, on }">
                              <v-list-item v-on="on" v-bind="attrs">
                                <v-list-item-action>
                                  <v-checkbox></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ item.name }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template> -->
                          </v-autocomplete>
                          <v-form ref="form" v-model="productValid">
                            <v-card v-if="selectedItems.length" class="mx-1 my-5">
                              <v-card-title class="subheading font-weight-bold">
                                Selected Products
                              </v-card-title>

                              <v-divider></v-divider>

                              <v-list dense>
                                <v-list-item
                                  v-for="item in selectedProducts"
                                  :key="item._id"
                                  class="align-end"
                                >
                                  <v-list-item-content>{{ item.name }}</v-list-item-content>
                                  <v-list-item-action class="flex-row align-end justify-end">
                                    <v-subheader class="align-start">Quantity:</v-subheader>
                                    <v-text-field
                                      v-model="item.count"
                                      class="mt-0 pt-0"
                                      type="number"
                                      style="width: 35px"
                                      :rules="numberRules"
                                      @input="onChangeCount($event, item._id)"
                                    ></v-text-field>
                                  </v-list-item-action>
                                </v-list-item>
                                <v-list-item 
                                      v-if="!productValid" 
                                      class="justify-center">
                                  <v-list-item-content>
                                    <v-alert
                                      block
                                      color="orange"
                                      dense
                                      text
                                      type="warning"
                                      icon="mdi-alert-outline"
                                    >Quantity must not less than 1.</v-alert>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-card>
                          </v-form>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  
                </v-form>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-row>
                  <v-col
                    cols="12"
                    sm="8"
                  >
                    <v-container 
                      v-if="project !== null && Object.keys(project).length"
                      class="image-wrapper"
                    >
                      <v-img
                        :src="defaultImage()"
                        aspect-ratio="1.7"
                        id="boxImage"
                        v-if="selectedBox !== null && Object.keys(selectedBox).length"
                      >
                        <vue-draggable-resizable
                          v-for="(item, i) in chosenBoxItems" :key="i"
                          :w="item.width" 
                          :h="item.height"
                          :x="100"
                          :y="100"
                          :drag-handle="item.dragSelector"
                          :lock-aspect-ratio="true"
                          @dragging="onDrag()" 
                          @resizing="onResize(item)" 
                          :parent="true"
                          :active="true"
                          :handles="['tl', 'tm', 'tr', 'mr', 'br', 'bm', 'bl', 'ml']"
                        >

                        <div class="img-block" style="height: 100%;">
                            <div :class="item.class" style="height: 100%;">
                              <v-img
                                contain
                                :src="item.productImageUrl"
                                aspect-ratio="1.7"
                                auto
                                style="height: 100%;"
                              >
                              </v-img>
                            </div>
                          </div>

                        </vue-draggable-resizable>
                      </v-img>
                      
                      <v-container
                        v-if="showDropPoint"
                        @drop="onDrop($event)"
                        @dragenter.prevent
                        @dragover.prevent
                        class="drop-point"
                      >
                        
                      </v-container>
                    </v-container>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="4"
                    class="text-right"
                  >
                    <v-list class="text-left"
                      v-if="itemDefaultAttr !== null"
                    >
                      <v-subheader>Products available</v-subheader>
                        <v-list-item
                          v-for="(item, i) in getList(1)"
                          :key="i"
                          :id="item._id"
                          draggable="true"
                          @dragstart="startDrag($event, item)"
                          @dragover.stop
                        >
                          <v-list-item-avatar>
                            <v-img :src="item.productImageUrl"></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title v-text="item.name"></v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-content class="text-right">
                            <v-list-item-title v-text="item.count"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-btn
                      elevation="2"
                      class="mt-5"
                      color="warning"
                      @click="onReset()"
                    >
                      Reset
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper> 
        </v-card-text>
      </v-card>
      <LoadingDialog :isLoading="isLoading" />
    </v-dialog>
  </v-row>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex';
  import VueDraggableResizable from 'vue-draggable-resizable'
  import domtoimage from 'dom-to-image';
  import LoadingDialog from './shared/LoadingDialog.vue';

  export default {
    name: 'AddProjectModal',
    // components: { VueResizable },
    components: { VueDraggableResizable, LoadingDialog  },
    props: {
      closeDialog: { type: Function },
      editedProjectId: { type: String },
      addDialog: { type: Boolean },
    },
    data () {
      return {
        productValid: true,
        boxImage: null,
        e1: 1,
        menu: false,
        count: 1,
        output: "",
        showDropPoint: false,
        reset: false,
        isLoading: false,
        numberRules: [
          v  => {
            if (!isNaN(parseFloat(v)) && v > 0 && v < 999) return true;
            return 'Number has to be between 0 and 999';
          },
        ],
        snackbar: {
          isOpen: false,
          text: ""
        },
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      }
    },
    methods: {
      ...mapActions([
        'addProject', 
        'uploadProjectImage', 
        'addProjectItems', 
        'addSelectedBox', 
        'updateProject', 
        'fetchProduct', 
        'fetchItemDefault', 
        'addBoxItems', 
        'openSnackbar', 
        'deleteProjectImage',
        'fetchCustomers',
        'setSelectedCustomer'
        ]),
      async onSave() {
        this.isLoading = true;
        await this.convertImage();
        this.project.selectedProducts = this.selectedProducts;
        this.project.selectedBox = this.selectedBox;
        this.project.customer = this.selectedCustomer;
        this.project.dueDate = this.date;
        if(this.editedProjectId !== null) {
          this.updateProject({...this.project})
            .then((res) => {
              if(res.hasError === undefined){
                this.chosenBoxItems = [];
                this.openSnackbar({text: "New project updated", status: "success"});
                this.closeDialog();
              } else {
                if(this.reset) this.deleteProjectImage(this.projectImageName);
                this.openSnackbar({text: res.message, status: "error"});
              }
              this.isLoading = false;
            });
        } else {
          this.addProject({...this.project})
            .then((res) => {
              if(res.hasError === undefined){
                this.chosenBoxItems = [];
                this.openSnackbar({text: "New project added", status: "success"});
                this.closeDialog();
              } else {
                this.deleteProjectImage(this.projectImageName);
                this.openSnackbar({text: res.message, status: "error"});
              }
              this.isLoading = false;
            });
          // this.selectedProducts.map(async (item) => {
          //   await this.updateProduct({_id: item._id, qty: item.qty - 1})
          // })
        }
      },

      async upload(file) {
        let formData = new FormData();

        formData.append('file', file, file.name);
        await this.uploadProjectImage(formData);
        return await this.projectImageName;
      },

      calculateTotals() {
        const items = [this.selectedBox, ...this.selectedProducts];

        this.initTotals();

        items.map(async(item) => {
          this.project.totalCost += parseInt(item.cop * item.count);
          this.project.totalSrp += parseInt(item.srp * item.count);
          this.project.totalProfit = parseFloat(this.project.totalSrp - this.project.totalCost).toFixed(2);
          this.project.totalMarkup = Math.round((this.project.totalProfit/this.project.totalCost) * 100).toFixed(2);
        });
      },

      async onContinue() {
        // this.project.count = this.count;
        await this.calculateTotals();
        this.validate();
        await this.fetchItemDefault(this.selectedProducts);
        this.e1 = this.productValid? 2: 1 ;
      },

      async onClose() {
        await this.selectedProducts.map((item) => item.selectedCount = 0);
        await this.fetchItemDefault(this.selectedProducts);
        this.chosenBoxItems = [];
        this.e1 = 1;
        this.closeDialog();
      },

      async convertImage() {
        if(this.editedProjectId !== null && !this.reset) {
          return;
        }
        let node = document.getElementById('boxImage');
        await domtoimage.toBlob(node)
          .then( async (blob) => {
            blob.name = "box-image.png";
            this.project.projectBoxImg = await this.upload(blob);
          })
          .catch(function (error) {
            console.error('oops, something went wrong!', error);
          });
      },

      async onChangeBox() {
        if(this.selectedBox !== null) {
          if(this.selectedBox.count === undefined) {
            this.selectedBox.count = 1;
          }
        }
      },

      async onChangeItems() {
        if(this.selectedProducts.length) {
          this.selectedProducts.map(async(item, i) => {
            if(this.selectedProducts[i].count === undefined || this.selectedProducts[i].count === null) {
              this.selectedProducts[i].count = 1;
            }
          })
        }
      },

      onChangeCount(value, id) {
        this.validate();
        let selectedProducts = this.selectedProducts;
        if(value <= 0) {
          const index = selectedProducts.findIndex((item) => item._id === id);
          selectedProducts[index].count = 1;
          this.selectedProducts = selectedProducts;
        }
        let index = this.selectedProducts.findIndex((item) => item._id == id);
        this.selectedProducts[index].count = parseInt(value);
      },

      initTotals() {
        this.project.totalCost = 0;
        this.project.totalSrp = 0;
        this.project.totalProfit = 0;
        this.project.totalMarkup = 0;
      },

      getList(list) {
        return this.itemDefaultAttr.filter((item) => item.list === list);
      },
      
      imgPath(name) {
        if(name)
          return `${process.env.VUE_APP_API_BASE_URL}/uploads${name}`;
      },

      imgProjectPath(name) {
        if(name)
          return `${process.env.VUE_APP_API_BASE_URL}/uploads${name}`;
      },

      eHandler() {
    
      },

      startDrag(event, item) {
        event.dataTransfer.dropEffect = "move"
        event.dataTransfer.effectAllowed = 'move'
        event.dataTransfer.setData('itemID', item._id);
        this.showDropPoint = true;
      },

      onDrag(x, y) {
        this.x = x
        this.y = y
      },

      onResize(item, x, y, width, height) {
        this.x = x
        this.y = y
        this.width = width
        this.height = height
      },

      async onDrop(event) {
        this.showDropPoint = false;
        const itemID = event.dataTransfer.getData('itemID')
        const item = this.itemDefaultAttr.find((item) => item._id === itemID)
        const index = this.itemDefaultAttr.findIndex((item) => item._id === itemID)
        const selectedItem = this.selectedProducts.find((item) => item._id === itemID)
        const selectedIndex = this.selectedProducts.findIndex((item) => item._id === itemID)
        if(parseInt(item.count)) {
          this.itemDefaultAttr[index].count = --this.itemDefaultAttr[index].count;
          this.selectedProducts[selectedIndex].selectedCount = selectedItem.count - this.itemDefaultAttr[index].count;
          this.chosenBoxItems.push(item);
        }
      },

      async onReset() {
        await this.selectedProducts.map((item) => item.selectedCount = 0);
        await this.fetchItemDefault(this.selectedProducts);
        this.chosenBoxItems = [];
        this.reset = true;
      },

      defaultImage() {
        const url = this.editedProjectId !== null && !this.reset && Object.keys(this.selectedBox).length ? this.imgProjectPath(this.project.projectBoxImg) : this.imgPath(this.selectedBox.productImageUrl);
        return url;
      },

      validate () {
        this.$refs.form.validate()
      },
    },
    computed: {
      ...mapGetters(['allProducts', 'allBoxes', 'allProjects', 'project', 'itemDefaultAttr', 'selectedItems', 'selectedBox', 'boxItems', 'projectImageName', 'allCustomers', 'selectedCustomer', 'status']),
      selectedProducts: {
        get() {
          return this.selectedItems;
        },
        set(value) {
          this.addProjectItems(value);
        }
      },
      chosenBox: {
        get() {
          return this.selectedBox;
        },
        set(value) {
          this.addSelectedBox(value);
        }
      },
      chosenBoxItems: {
        get() {
          return this.boxItems;
        },
        set(value) {
          this.addBoxItems(value);
        }
      },
      _selectedCustomer: {
        get() {
          return this.selectedCustomer
        },
        set(value) {
          this.setSelectedCustomer(value);
        }
      }
    },
    async created() {
      await this.fetchCustomers()
      this.project.count = this.project.count? this.project.count: 1;
      if(this.project.dueDate !== undefined) {
        this.date = this.project.dueDate;
      }
    },
  }
</script>

<style>
  .resizable {
    position: absolute !important;
    width: 150px;
    height: auto;
    padding: 0;
    cursor: pointer;
  }

  .image-wrapper {
    position: relative;
  }

  .drop-point {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #00000033;
  }

  .vdr {
    touch-action: none;
    position: absolute;
    box-sizing: border-box;
    border: 1px dashed #00000000;
  }

  .vdr.active {
    border: 1px dashed black;
  }

  .handle {
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    background: #EEE;
    border: 1px solid #333;
  }
  .handle-tl {
    top: -10px;
    left: -10px;
    cursor: nw-resize;
  }
  .handle-tm {
    top: -10px;
    left: 50%;
    margin-left: -5px;
    cursor: n-resize;
  }
  .handle-tr {
    top: -10px;
    right: -10px;
    cursor: ne-resize;
  }
  .handle-ml {
    top: 50%;
    margin-top: -5px;
    left: -10px;
    cursor: w-resize;
  }
  .handle-mr {
    top: 50%;
    margin-top: -5px;
    right: -10px;
    cursor: e-resize;
  }
  .handle-bl {
    bottom: -10px;
    left: -10px;
    cursor: sw-resize;
  }
  .handle-bm {
    bottom: -10px;
    left: 50%;
    margin-left: -5px;
    cursor: s-resize;
  }
  .handle-br {
    bottom: -10px;
    right: -10px;
    cursor: se-resize;
  }
  @media only screen and (max-width: 768px) {
    [class*="handle-"]:before {
      content: '';
      left: -10px;
      right: -10px;
      bottom: -10px;
      top: -10px;
      position: absolute;
    }
  }

</style>