import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueMeta from 'vue-meta';
import VueCookies from 'vue-cookies'
import JsonExcel from "vue-json-excel";
import SnackbarDialog from './components/shared/SnackbarDialog.vue';

require('@/store/subscriber')

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

Vue.use(VueMeta);
Vue.use(VueCookies)
Vue.component("downloadExcel", JsonExcel);
Vue.$cookies.config('7d')
Vue.config.productionTip = false
Vue.component("SnackbarDialog", SnackbarDialog)

store.dispatch('attempt', Vue.$cookies.get('token')).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
});


